<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>disability-information</title>
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="18" cy="6" r="5.25" />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="18" y1="8.625" x2="18" y2="6" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18,3.381a.375.375,0,1,0,.375.375A.375.375,0,0,0,18,3.381"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="7.125" cy="3.375" r="2.625" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M11.359,21.092a6,6,0,0,1-9.218-7.683"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M16.1,21.211l-2.85-3.42A1.493,1.493,0,0,0,12.1,17.25H6.75a1.5,1.5,0,0,1-1.5-1.5v-6a1.5,1.5,0,0,1,1.5-1.5h3.809a1.5,1.5,0,0,1,0,3H8.25v3H12.1a4.489,4.489,0,0,1,3.457,1.62L18.4,19.289A1.5,1.5,0,0,1,16.1,21.211Z"
    />
  </svg>
</template>
